import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/location-permission',
  },
  {
    path: '/camera-permission',
    name: 'CameraPermission',
    component: () => import('../views/CameraPermission.vue')
  },

  {
    path: '/location-permission',
    name: 'LocationPermission',
    component: () => import('../views/LocationPermission.vue')
  },

  {
    path: '/on-boarding',
    name: 'OnBoarding',
    component: () => import('../views/OnBoarding.vue')
  },

  {
    path: '/booking/:eventId',
    name: 'Booking',
    component: () => import('../views/Booking.vue')

  },

  {
    path: '/reservation',
    name: 'Reservation',
    component: () => import('../views/Reservation.vue'),
    props: true
    
  },

  // waiting 
  {
    path: '/waiting',
    name: 'Waiting',
    props: true,
    component: () => import('../views/Waiting.vue')
  },

  // quiz
  {
    path: '/quiz',
    name: 'Quiz',
    component: () => import('../views/Quiz.vue'),
    props: true
  },

  // matches
  {
    path: '/matches',
    name: 'Matches',
    component: () => import('../views/Matches.vue')
  },

  // chats
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('../views/Chats.vue')
  },

  // chat
  {
    path: '/chat',
    name: 'Chat',
    component: () => import('../views/Chat.vue')
  },

  {
    path: '/chat/quiz',
    name: 'ChatQuiz',
    component: () => import('../views/ChatQuiz.vue')
  },

  // profile
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
    props: true
  },
]

const router = new VueRouter({
  mode: 'abstract',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// @ts-ignore
import Vuesax from 'vuesax'
// @ts-ignore
import * as Sentry from "@sentry/vue";


import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
  colors: {
    primary:'#24B5C9',
    dark:'#ffffff',
  }
})

import 'boxicons/css/boxicons.min.css';

import './assets/fonts/stylesheet.css';
import i18n from './i18n'

Vue.config.productionTip = false

Sentry.init({
  Vue,
  dsn: "https://b25270ea3628e68ef8274b83267d86a5@sentry-wised.italynorth.cloudapp.azure.com/2",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["match.partyamo.it"],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

export default Vue;